<template>
  <section>
    <div class="ll-box">
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('User') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'user-list' }" >
          {{ $t('User List') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Create User') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- back button -->
    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'user-list' }"
    >
      <!-- <feather-icon icon="RotateCcwIcon" /> -->
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>
    </div>

    <!-- base info -->
    <validation-observer ref="accountRules">
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('Account Info') }}</b-card-title>
        <div class="ll-required">* {{ $t('Required Fields') }}</div>
      </b-card-header>
      <b-card-body class="ll-cbody">
        <b-row>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="accountType"
            >
            <validation-provider
              #default="{ errors }"
              name="accountType"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Account Type') }}</label>
              <v-select
                id="accountType"
                v-model="account"
                :options="accountList"
                label="name"
                @input="selAccount"
                placeholder="Please select an account type"
              ></v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="password"
            >
            <validation-provider
              #default="{ errors }"
              name="password"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Password') }}</label>
              <div class="ll-pwdbox">
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                <b-form-input
                  id="password"
                  v-model="userInfo.password"
                  :type="passwordFieldType"
                  class="form-control-merge"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
                </b-input-group>
                <!-- <b-form-input
                  id="password"
                  type="password"
                  v-model="userInfo.password"
                /> -->
                <b-button variant="outline-info" @click="autoCreatePwd" class="ll-autoBtn">{{ $t('Auto create pwd') }}</b-button>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="email"
            >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <label class="ll-boldText">{{ $t('Email') }}</label>
              <b-form-input
                id="email"
                type="email"
                v-model="userInfo.email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('Personal Info') }}</b-card-title>
      </b-card-header>
      <b-card-body class="ll-cbody">
        <b-row>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="firstName"
            >
            <validation-provider
              #default="{ errors }"
              name="firstName"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('First Name') }}</label>
              <b-form-input
                id="firstName"
                v-model="userInfo.firstName"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="lastname"
            >
            <validation-provider
              #default="{ errors }"
              name="lastname"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Last Name') }}</label>
              <b-form-input
                id="lastname"
                v-model="userInfo.lastName"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Phone')"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="userInfo.phone"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Department')"
              label-for="department"
            >
              <b-form-input
                id="department"
                v-model="userInfo.departmentName"
              />
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="company"
            >
            <validation-provider
              #default="{ errors }"
              name="company"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Company') }}</label>
              <v-select
                id="company"
                v-model="org"
                :options="orgList"
                label="name"
                @input="selOrg"
                placeholder="Please selsect a company"
              ></v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Merchant(seller)code')"
              label-for="merchantCode"
            >
              <b-form-input
                id="merchantCode"
                v-model="userInfo.merchantCode"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('Permission') }}</b-card-title>
      </b-card-header>
      <b-card-body class="ll-cbody">
        <b-row>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="role"
            >
            <validation-provider
              #default="{ errors }"
              name="role"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Role') }}</label>
              <v-select
                id="role"
                v-model="role"
                :options="roleList"
                label="name"
                @input="selRole"
                multiple
                placeholder="Please select a role"
              ></v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    </validation-observer>
    <div class="ll-subBtn">
      <b-button
        variant="info"
        @click.prevent="validationForm"
      >
        {{ $t('Submit') }}
      </b-button>
    </div>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormRadio,
  BImg,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadio,
    vSelect,
    BImg,
    BInputGroupAppend,
    BInputGroup,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userInfo: {
        // username: '',
        password: '',
        email: '',
        phoneAreaCode: '',
        phone: '',
        // fullName: '',
        fullNameLocale: '',
        departmentName: '',
        company: '',
        coverImg: '',
        // sex: null,
        isEnabled: true,
        isLocked: false,
        organizationId: null,
        roleIds: [],
        firstName: '',
        lastName: '',
        accountType: '',
        merchantCode: '',
      },
      role: '',
      required,
      email,
      orgList: [],
      org: '',
      roleList: [],
      account: '',
      accountList: [
        { name: 'User Account', key: 'UA' },
        { name: 'System Account (for API intergration only)', key: 'SA' },
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {},
  mounted() {
    this.queryOrg()
    this.queryRole()
  },
  methods: {
    // 生成随机密码
    autoCreatePwd() {
      const pasArr = ['a','b','c','d','e','f','g','h','i','j','k','l','m',
        'n','o','p','q','r','s','t','u','v','w','x','y','z','A','B','C','D',
        'E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U',
        'V','W','X','Y','Z','0','1','2','3','4','5','6','7','8','9','_','-',
        '$','%','&','@','+','!']
      let pwd = ''
      const pwdLen = pasArr.length
      for (let i = 0; i<8; i++){
        let x =Math.floor(Math.random()*pwdLen)
        pwd += pasArr[x]
      }
      this.userInfo.password = pwd
    },
    // 查询组织
    queryOrg() {
      this.$http.get('/admin/settings/organization/findAllOrganization').then(res => {
        this.orgList = res.data.data
      })
    },
    selOrg(o) {
      this.userInfo.organizationId = o.id
    },
    // 查询角色
    queryRole() {
      this.$http.get('/admin/settings/role/roleList').then(res => {
        this.roleList = res.data.data
      })
    },
    selRole(r) {
      const arr = []
      r.map(item => {
        arr.push(item.roleId)
      })
      this.userInfo.roleIds = arr
    },
    selAccount(a) {
      this.userInfo.accountType = a.key
    },
    AddUser() {
      this.$http.post('/user/createUser', this.userInfo).then(res => {
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Create User Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'user-list' })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    validationForm() {
      this.$refs.accountRules.validate().then(success => {
        if (success) {
          this.AddUser()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
  },
}
</script>

<style scoped>
.ll-subBtn{
  text-align: right;
  height: 50px;
}
.ll-radioBox{
  display: flex;
  justify-content: space-evenly;
}
.ll-pwdbox{
  display: flex;
}
.ll-pwdbox>button{
  height: 2.714rem;
  width: 30%;
  margin-left: 1rem;
}
.ll-autoBtn{
  padding: 0;
}
</style>
<style>
</style>
